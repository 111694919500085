var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isFiltered
      ? _c(
          "div",
          [
            _vm.isLoader
              ? _c("Loader", { attrs: { loading: _vm.isLoader } })
              : _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "table-wrapper" }, [
                    _c(
                      "table",
                      { staticClass: "pioti-table table-auto products" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Brand")]),
                            _c("th", [_vm._v("Product Name")]),
                            _c("th", [_vm._v("Product Start Date")]),
                            _c("th", [_vm._v("Product End Date")]),
                            _c("th", { staticClass: "actions" })
                          ])
                        ]),
                        _vm.tableDataRows[0].ProductId != 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.tableDataRows, function(row, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [_vm._v(_vm._s(row.BrandName))]),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.ProductName) + " "
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          row.ProductStartDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(row.ProductEndDate)
                                      )
                                    )
                                  ]),
                                  _vm.role
                                    ? _c(
                                        "td",
                                        { staticClass: "actions" },
                                        [
                                          _vm.role.isUser
                                            ? _c(
                                                "IconButton",
                                                {
                                                  attrs: {
                                                    buttonType: _vm.buttonType
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openModalProduct()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Edit")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "5" } },
                                  [_c("NoDetailsBox")],
                                  1
                                )
                              ])
                            ])
                      ]
                    )
                  ]),
                  _vm.tableDataRows[0].ProductId != 0
                    ? _c("div", { staticClass: "product-variations" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tableDataRows[0].ProductId == 0
                                  ? "No Variations found"
                                  : "Product Variations for " +
                                      _vm.tableDataRows[0].ProductName
                              ) +
                              " "
                          )
                        ]),
                        _c("table", { staticClass: "pioti-table table-auto" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Country")]),
                              _c("th", [_vm._v("Language")]),
                              _c("th", [_vm._v("Marketed")]),
                              _c("th", [_vm._v("Variation Name")]),
                              _c("th", [_vm._v("Variation Start Date")]),
                              _c("th", [_vm._v("Variation End Date")])
                            ])
                          ]),
                          _vm.tableDataRows[0].ProductVariations
                            ? _c(
                                "tbody",
                                _vm._l(
                                  _vm.tableDataRows[0].ProductVariations,
                                  function(row, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(_vm._s(row.Country_Name))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(row.Language_Name))
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "tooltip table-tooltip"
                                        },
                                        [
                                          _c("Checkbox", {
                                            attrs: { checked: row.Marketed }
                                          }),
                                          row.Marketed
                                            ? _c("Tooltip", {
                                                attrs: {
                                                  position: "left",
                                                  hoverText:
                                                    "Product will flow to WIOP"
                                                }
                                              })
                                            : _c("Tooltip", {
                                                attrs: {
                                                  position: "left",
                                                  hoverText:
                                                    "Product will not flow to WIOP"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "tooltip table-tooltip"
                                        },
                                        [
                                          _c("Ellipsis", {
                                            attrs: {
                                              data: row.Variation_Name,
                                              subString: _vm.subString
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.VariationStartDate
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.VariationEndDate
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { colspan: "6" } },
                                    [_c("NoDetailsBox")],
                                    1
                                  )
                                ])
                              ])
                        ])
                      ])
                    : _vm._e()
                ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c("InfoBox", {
              attrs: {
                message:
                  "Select Product or Variation to view General Information"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }