var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c("section", { staticClass: "product-report" }, [
              _c("div", { staticClass: "title flex justify-between" }, [
                _c("div", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "div",
                  [
                    _c("ExportExcel", {
                      attrs: {
                        exportData: _vm.reportsData,
                        tableHeaders: _vm.exportDataHeaders,
                        infoTableDataRows: _vm.infoTableData,
                        fileName: _vm.exportFileName
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "product__info" }, [
                _c("div", { staticClass: "filters flex" }, [
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [
                        _vm._v("CUC Code:"),
                        _c("span", { staticClass: "text-red-600" }, [
                          _vm._v("*")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isCucCodeRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: {
                                options: _vm.cucCodes,
                                placeholder: "Enter 3 digits of CUC Code",
                                apiPath: _vm.apiPath,
                                isAutoFetch: "",
                                autoFetchType: "CucReports"
                              },
                              on: { "selected-item": _vm.setSelectedCucCode },
                              model: {
                                value: _vm.selectedCucCode,
                                callback: function($$v) {
                                  _vm.selectedCucCode = $$v
                                },
                                expression: "selectedCucCode"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: { loading: _vm.isCucLoader, size: _vm.size }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "view-reports" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedCucCode === ""
                            ? ""
                            : "Report for Cuc Code " +
                                _vm.selectedCucCode.split("|")[0] +
                                ", Revision " +
                                _vm.selectedCucCode.split("|")[1]
                        ) +
                        " "
                    )
                  ]),
                  _vm.isLoader
                    ? _c("Loader", { attrs: { loading: _vm.isLoader } })
                    : _c("div", { staticClass: "table-wrapper" }, [
                        _c("table", { staticClass: "pioti-table table-auto" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("CUC Code")]),
                              _c("th", [_vm._v("CUC Revision")]),
                              _c("th", [_vm._v("Description")]),
                              _c(
                                "th",
                                { attrs: { title: "Planned Effective Date" } },
                                [_vm._v("Planned Effective Date")]
                              ),
                              _c(
                                "th",
                                { attrs: { title: "Ingredient Name" } },
                                [_vm._v("Ingredient Name")]
                              ),
                              _c(
                                "th",
                                { attrs: { title: "Inter Spec Name" } },
                                [_vm._v("Inter Spec Name")]
                              ),
                              _c("th", [_vm._v("IUPAC Name")]),
                              _c("th", { attrs: { title: "EuroPhar Name" } }, [
                                _vm._v("EuroPhar Name")
                              ]),
                              _c("th", [_vm._v("EINECS Number")]),
                              _c(
                                "th",
                                { attrs: { title: "Ingredient Function" } },
                                [_vm._v("Ingredient Function")]
                              ),
                              _c("th", [_vm._v("Inclusion Level")])
                            ])
                          ]),
                          _vm.reports.length > 0
                            ? _c(
                                "tbody",
                                _vm._l(_vm.reports, function(row, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(row.CucCode))]),
                                    _c("td", [_vm._v(_vm._s(row.CucRevision))]),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.CucDescription,
                                            subString: _vm.subString,
                                            position: "right"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.PlannedEffectiveDate) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.IngredientName,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.InterSpecName,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.IUPACName,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.EuroPharName,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.EINECSNumber,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "tooltip table-tooltip" },
                                      [
                                        _c("Ellipsis", {
                                          attrs: {
                                            data: row.IngredientFunction,
                                            subString: _vm.subString,
                                            position: "left"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(_vm._s(row.InclusionLevel))
                                    ])
                                  ])
                                }),
                                0
                              )
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { colspan: "12" } },
                                    [_c("NoDetailsBox")],
                                    1
                                  )
                                ])
                              ])
                        ])
                      ])
                ],
                1
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }