<template>
  <div class="page-container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else class="container">
      <section class="product-report">
        <div class="title flex justify-between">
          <div>{{ title }}</div>
          <div>
            <ExportExcel
              :exportData="reportsData"
              :tableHeaders="exportDataHeaders"
              :infoTableDataRows="infoTableData"
              :fileName="exportFileName"
            />
          </div>
        </div>
        <div class="product__info">
          <div class="filters flex">
            <!--Identifier-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Identifier:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="identifiers"
                  v-model="selectedIdentifier"
                  @selected-item="setSelectedIdentifier"
                  v-if="isIdentifierRefresh"
                /><Loader :loading="isIdentifierLoader" :size="size" />
              </div>
            </div>
            <!-- If the identifier is CUCCODE-->
            <!--CUC code-->
            <div class="flex flex-row filters__item" v-if="identifierId === 1">
              <label class="label font-bold text-right pr-4 w-1/4"
                >CUC Code:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-4 relative w-3/4">
                <AutoCompleteDropdown
                  :options="cucCodes"
                  v-model="selectedCucCode"
                  @selected-item="setSelectedCucCode"
                  v-if="isCucCodeRefresh"
                  placeholder="Enter 3 digits of CUC Code"
                  :apiPath="apiPath"
                  isAutoFetch
                  autoFetchType="CucReports"
                /><Loader :loading="isCucLoader" :size="size" />
              </div>
            </div>
            <!--Revision-->
            <div class="flex flex-row filters__item" v-if="identifierId === 1">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Revision:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="revisions"
                  v-model="selectedRevision"
                  @selected-item="setSelectedRevision"
                  v-if="isRevisionsRefresh"
                  :limit="limit"
                /><Loader :loading="isRevisionLoader" :size="size" />
              </div>
            </div>
            <!-- If the identifier is Product-->
            <!--Brand-->
            <div class="flex flex-row filters__item" v-if="identifierId === 2">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Brand:</label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="brands"
                  v-model="selectedBrand"
                  @selected-item="setSelectedBrand"
                  v-if="isBrandRefresh"
                />
                <Loader :loading="isBrandLoader" :size="size" />
              </div>
            </div>
            <!--Product-->
            <div class="flex flex-row filters__item" v-if="identifierId === 2">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Product:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="products"
                  v-model="selectedProduct"
                  @selected-item="setSelectedProduct"
                  v-if="isProductRefresh"
                  placeholder="type minimum 3 letters to search products"
                  :apiPath="apiProductPath"
                  isAutoFetch
                  autoFetchType="ProductReports"
                /><Loader :loading="isProductLoader" :size="size" />
              </div>
            </div>
            <!--Variation-->
            <div class="flex flex-row filters__item" v-if="identifierId === 2">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Variation:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="variations"
                  v-model="selectedVariation"
                  @selected-item="setSelectedVariation"
                  v-if="isVariationRefresh"
                  placeholder="type minimum 3 letters to search variations"
                  :apiPath="apiVariationPath"
                  isAutoFetch
                  autoFetchType="VariationReports"
                />
                <Loader :loading="isVariationLoader" :size="size" />
              </div>
            </div>
            <!-- <div class="filters__item justify-evenly">
              <ExportExcel
                :exportData="reportsData"
                :tableHeaders="exportDataHeaders"
                :infoTableDataRows="infoTableData"
                :fileName="exportFileName"
              />
            </div> -->
          </div>
        </div>
        <!-- View reports for selected cuc code -->
        <div class="container" style="margin-top: 10px; padding-left: 0">
          <Loader :loading="isLoader" v-if="isLoader" />
          <div class="view-reports" v-else>
            <div class="title">
              <p v-if="identifierId === 1">
                {{
                  selectedCucCode != "" && selectedRevision != ""
                    ? "Report for Cuc Code " +
                      selectedCucCode +
                      ", Revision " +
                      selectedRevision
                    : ""
                }}
              </p>
              <p v-else>
                {{
                  selectedProduct != ""
                    ? "Report for Product " + selectedProduct
                    : ""
                }}
              </p>
            </div>
            <div class="table-wrapper">
              <table class="pioti-table table-auto">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Language</th>
                    <th>Brand</th>
                    <th>CUC Code</th>
                    <th>Revision</th>
                    <th>Product</th>
                    <th>Marketed</th>
                    <th>Variation</th>
                    <th>Variation Start Date</th>
                    <th>Variation End Date</th>
                    <th>CUC Status</th>
                    <th>CUC Start Date</th>
                    <th>CUC End Date</th>
                    <th>Historic</th>
                  </tr>
                </thead>
                <tbody v-if="reports.length > 0">
                  <tr v-for="(row, index) in reports" :key="index">
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Country"
                        :subString="subString"
                        position="right"
                      />
                    </td>
                    <td>{{ row.Language }}</td>
                    <td>{{ row.Brand }}</td>
                    <td>{{ row.PlmMaterialId }}</td>
                    <td>{{ row.Revision }}</td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Product"
                        :subString="subString"
                        position="right"
                      />
                    </td>
                    <td class="tooltip table-tooltip">
                      <Checkbox :checked="row.Marketed" />
                      <Tooltip
                        v-if="row.Marketed"
                        :position="'left'"
                        :hoverText="'Product will flow to WIOP'"
                      />
                      <Tooltip
                        v-else
                        :position="'left'"
                        :hoverText="'Product will not flow to WIOP'"
                      />
                    </td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.ProductVariationName"
                        :subString="subString"
                        position="right"
                      />
                    </td>
                    <td>{{ row.ProductVariationStartDate | formatDate }}</td>
                    <td>{{ row.ProductVariationEndDate | formatDate }}</td>
                    <td>{{ row.CucStatusText }}</td>
                    <td>{{ row.CucStartDate | formatDate }}</td>
                    <td>{{ row.CucEndDate | formatDate }}</td>
                    <td class="tooltip table-tooltip">
                      <Checkbox :checked="row.Historic" />
                      <Tooltip
                        v-if="row.Historic"
                        :position="'left'"
                        :hoverText="'Formulation will flow to WIOP'"
                      />
                      <Tooltip
                        v-else
                        :position="'left'"
                        :hoverText="'Formulation will not flow to WIOP'"
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="14">
                      <NoDetailsBox />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import authentication from "../../authentication";
import { mapMutations } from "vuex";
import "../../filters";
import formulationMappingService from "../../services/formulationMappings";
import reportService from "../../services/reports";
import loggerService from "../../services/logger";

export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "../common/AutoCompleteDropdown"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    ExportExcel: () =>
      import(/* webpackPrefetch: true */ "../common/ExportExcel"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
  },
  data: () => ({
    subString: 10,
    limit: 1,
    title: "Product Formulation Mapping Report",
    isLoader: true,
    isCucLoader: false,
    size: "5px",
    isCucCodeRefresh: true,
    selectedCucCode: "",
    cucId: "",
    cucCodes: [],
    revisions: [],
    reports: [],
    reportsData: [],
    exportDataHeaders: [
      {
        Country: "Country",
        Language: "Language",
        Brand: "Brand",
        CUCCode: "CUCCode",
        Revision: "Revision",
        Product: "Product",
        Marketed: "Marketed",
        Variation: "Variation",
        VariationStartDate: "VariationStartDate",
        VariationEndDate: "VariationEndDate",
        CUCStatus: "CUCStatus",
        CUCStartDate: "CUCStartDate",
        CUCEndDate: "CUCEndDate",
        Historic: "Historic",
      },
    ],
    infoTableData: [],
    exportFileName: "ProductFormulationMappingReport",
    //isDownloading: false,
    selectedRevision: "",
    isRevisionLoader: false,
    isRevisionsRefresh: true,
    identifiers: [
      { Id: 1, Value: "CUC Code" },
      { Id: 2, Value: "Product" },
    ],
    selectedIdentifier: "",
    identifierId: "",
    isIdentifierRefresh: true,
    isIdentifierLoader: false,
    brands: [],
    products: [],
    selectedBrand: "",
    brandId: "",
    selectedProduct: "",
    productId: "",
    isBrandRefresh: true,
    isProductRefresh: true,
    isBrandLoader: false,
    isProductLoader: false,
    header: null,
    variations: [],
    selectedVariation: "",
    variationId: "",
    isVariationRefresh: true,
    isVariationLoader: false,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.updateCountries("");
    }, 500);
  },
  computed: {
    apiPath() {
      return `FormulationMapping/GetCUCCodesForFilter/${authentication.getUserEmail()}/`;
    },
    apiVariationPath() {
      return "FormulationMapping/GetProductVariationNamesForDD";
    },
    apiProductPath() {
      return "FormulationMapping/GetProductListByContriesandBrands";
    },
  },
  methods: {
    // ...mapActions({
    //   updateBrand: "fetchBrandInReports",
    //   updateCountries: "fetchCountriesInReports",
    // }),
    ...mapMutations({
      updateCountries: "SET_COUNTRIES_IN_REPORTS",
      updateBrand: "SET_BRAND_IN_REPORTS",
    }),
    clearSelected() {
      this.selectedCucCode = "";
      this.selectedRevision = "";
      this.selectedBrand = "";
      this.selectedProduct = "";
      this.selectedIdentifier = "";
      this.cucCodes = [];
      this.revisions = [];
      this.brands = [];
      this.products = [];
      this.variations = [];
    },
    setSelectedIdentifier(identifier) {
      if (identifier === true) {
        this.selectedIdentifier = "";
        this.identifierId = "";
        this.reports = [];
        this.reportsData = [];
        this.clearSelected();
        return;
      }
      this.selectedIdentifier = identifier.Value;
      this.identifierId = identifier.Id;
      if (this.identifierId === 2) {
        this.isBrandLoader = true;
        this.isProductLoader = true;
        this.isVariationLoader = true;
        setTimeout(() => {
          this.isBrandLoader = false;
          this.isProductLoader = false;
          this.isVariationLoader = false;
        }, 500);
        this.getBrands();
      }
    },
    setSelectedCucCode(cucCode) {
      if (cucCode === true) {
        this.selectedCucCode = "";
        this.selectedRevision = "";
        this.revisions = [];
        this.reports = [];
        this.reportsData = [];
        this.isRevisionRefresh();
        return;
      }
      this.selectedCucCode = cucCode.Value;
      this.cucId = cucCode.Id;
      this.isRevisionLoader = true;
      setTimeout(() => {
        this.isRevisionLoader = false;
      }, 500);
      this.getRevisions(cucCode.Value);
    },
    setSelectedRevision(revision) {
      if (revision === true) {
        this.selectedRevision = "";
        this.reports = [];
        this.reportsData = [];
        return;
      }
      this.selectedRevision = revision.Value;
      this.fetchReport();
    },
    setSelectedBrand(brand) {
      if (brand === true) {
        this.selectedBrand = "";
        this.brandId = "";
        this.selectedProduct = "";
        this.productId = "";
        this.selectedVariation = "";
        this.variationId = "";
        this.products = [];
        this.reports = [];
        this.reportsData = [];
        this.isProductsRefresh();
        this.isVariationsRefresh();
        return;
      }
      //this.selectedProduct = "";
      this.isProductLoader = true;
      this.isVariationLoader = true;
      setTimeout(() => {
        this.isProductLoader = false;
        this.isVariationLoader = false;
      }, 500);
      this.selectedBrand = brand.Value;
      this.brandId = brand.Id;
      this.updateBrand(brand.Value);
      this.isProductsRefresh();
      this.isVariationsRefresh();
    },
    setSelectedProduct(product) {
      if (product === true) {
        this.selectedProduct = "";
        this.productId = "";
        this.reports = [];
        this.selectedVariation = "";
        this.variationId = "";
        this.reportsData = [];
        this.isVariationsRefresh();
        return;
      }
      this.isVariationLoader = true;
      setTimeout(() => {
        this.isVariationLoader = false;
      }, 500);
      this.selectedProduct = product.Value;
      this.productId = product.Id;
      this.isVariationsRefresh();
      this.fetchReport();
    },
    setSelectedVariation(variation) {
      if (variation === true) {
        this.selectedVariation = "";
        this.variationId = "";
        this.reports = [];
        this.reportsData = [];
        this.fetchReport();
        return;
      }
      this.selectedVariation = variation.Value;
      this.variationId = variation.Id;
      this.fetchReport();
    },
    async getRevisions(cucCode) {
      try {
        const { data } = await formulationMappingService.getCUCRevisions(
          cucCode
        );
        this.revisions = data;
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.revisions = [];
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      }
    },
    async getBrands() {
      const payLoad = {
        cIds: "",
      };
      const { data } = await reportService.getBrands(payLoad);

      this.brands = data;
      this.isBrandLoader = false;
      this.isBrandsRefresh();
    },
    async fetchReport() {
      if (this.identifierId === 1) {
        try {
          const payLoad = {
            cucCode: this.selectedCucCode,
            revision: this.selectedRevision,
          };
          const { data } = await reportService.getCucCodeReport(payLoad);

          if (data.length === 0 || data === null || data === undefined) {           
            loggerService.logInfo("No data to view/download");
            this.reports = [];
            this.reportsData = [];
          } else {
            this.reports = data;
            this.reportsData = data;
            delete this.reportsData[0]["TransId"];
            delete this.reportsData[0]["CountryId"];
            delete this.reportsData[0]["LanguageId"];
            delete this.reportsData[0]["BrandId"];
            delete this.reportsData[0]["ProductId"];
            delete this.reportsData[0]["ProductVariationId"];
            delete this.reportsData[0]["CucStatus"];
            this.infoTableData[0] = {
              Identifier: this.selectedIdentifier,
              CucCode: this.selectedCucCode,
              Revision: this.selectedRevision,
            };
            this.header = [
              "Identifier: " + this.selectedIdentifier,
              "CUC Code: " + this.selectedCucCode,
              "Revision: " + this.selectedRevision,
              " ",
            ];
            return data;
          }
        } catch (error) {
          loggerService.logError(error);
          this.reports = [];
          // this.isDownloading = false;
        }
      } else {
        let payLoad = {
          productId: this.productId === "" ? null : this.productId,
          variationId: this.variationId === "" ? null : this.variationId,
        };
        try {
          const { data } = await reportService.getProductReport(payLoad);

          if (data.length === 0 || data === null || data === undefined) {
            loggerService.logInfo("No data to view/download");
            this.reports = [];
            // this.isDownloading = false;
          } else {
            this.reports = data;
            this.reportsData = data;
            delete this.reportsData[0]["TransId"];
            delete this.reportsData[0]["CountryId"];
            delete this.reportsData[0]["LanguageId"];
            delete this.reportsData[0]["BrandId"];
            delete this.reportsData[0]["ProductId"];
            delete this.reportsData[0]["ProductVariationId"];
            delete this.reportsData[0]["CucStatus"];
            this.infoTableData[0] = {
              Identifier: this.selectedIdentifier,
              Brand: this.selectedBrand,
              Product: this.selectedProduct,
              Variation: this.selectedVariation,
            };
            this.header = [
              "Identifier: " + this.selectedIdentifier,
              "Brand: " + this.selectedBrand,
              "Product: " + this.selectedProduct,
              "Variation:" + this.selectedVariation,
              "",
              " ",
            ];
            return data;
          }
        } catch (error) {
          loggerService.logInfo(error);
          this.reports = [];
          // this.isDownloading = false;
        }
      }
    },
    isRevisionRefresh() {
      this.isRevisionsRefresh = false;
      this.$nextTick(() => {
        this.isRevisionsRefresh = true;
      });
    },
    isBrandsRefresh() {
      this.isBrandRefresh = false;
      this.$nextTick(() => {
        this.isBrandRefresh = true;
      });
    },
    isProductsRefresh() {
      this.isProductRefresh = false;
      this.$nextTick(() => {
        this.isProductRefresh = true;
      });
    },
    isVariationsRefresh() {
      this.isVariationRefresh = false;
      this.$nextTick(() => {
        this.isVariationRefresh = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-report {
  table {
    tr {
      th,
      td {
        min-width: auto;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          min-width: 70px;
          max-width: 70px;
        }
        &:nth-child(9) {
          min-width: 120px;
          max-width: 120px;
        }
        &:nth-child(10) {
          min-width: 115px;
          max-width: 115px;
        }
      }
    }
  }
}
.table-wrapper {
  max-height: 170px;
  overflow-y: auto;
}
</style>
