var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c("section", { staticClass: "product-report" }, [
              _c("div", { staticClass: "title flex justify-between" }, [
                _c("div", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "div",
                  [
                    _c("ExportExcel", {
                      attrs: {
                        exportData: _vm.reportsData,
                        tableHeaders: _vm.exportDataHeaders,
                        infoTableDataRows: _vm.infoTableData,
                        fileName: _vm.exportFileName
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "product__info" }, [
                _c("div", { staticClass: "filters flex" }, [
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [
                        _vm._v("Identifier:"),
                        _c("span", { staticClass: "text-red-600" }, [
                          _vm._v("*")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isIdentifierRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: { options: _vm.identifiers },
                              on: {
                                "selected-item": _vm.setSelectedIdentifier
                              },
                              model: {
                                value: _vm.selectedIdentifier,
                                callback: function($$v) {
                                  _vm.selectedIdentifier = $$v
                                },
                                expression: "selectedIdentifier"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: {
                            loading: _vm.isIdentifierLoader,
                            size: _vm.size
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.identifierId === 1
                    ? _c(
                        "div",
                        { staticClass: "flex flex-row filters__item" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "label font-bold text-right pr-4 w-1/4"
                            },
                            [
                              _vm._v("CUC Code:"),
                              _c("span", { staticClass: "text-red-600" }, [
                                _vm._v("*")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "select-box pl-4 relative w-3/4" },
                            [
                              _vm.isCucCodeRefresh
                                ? _c("AutoCompleteDropdown", {
                                    attrs: {
                                      options: _vm.cucCodes,
                                      placeholder: "Enter 3 digits of CUC Code",
                                      apiPath: _vm.apiPath,
                                      isAutoFetch: "",
                                      autoFetchType: "CucReports"
                                    },
                                    on: {
                                      "selected-item": _vm.setSelectedCucCode
                                    },
                                    model: {
                                      value: _vm.selectedCucCode,
                                      callback: function($$v) {
                                        _vm.selectedCucCode = $$v
                                      },
                                      expression: "selectedCucCode"
                                    }
                                  })
                                : _vm._e(),
                              _c("Loader", {
                                attrs: {
                                  loading: _vm.isCucLoader,
                                  size: _vm.size
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.identifierId === 1
                    ? _c(
                        "div",
                        { staticClass: "flex flex-row filters__item" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "label font-bold text-right pr-4 w-1/4"
                            },
                            [
                              _vm._v("Revision:"),
                              _c("span", { staticClass: "text-red-600" }, [
                                _vm._v("*")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "select-box pl-2 relative w-3/4" },
                            [
                              _vm.isRevisionsRefresh
                                ? _c("AutoCompleteDropdown", {
                                    attrs: {
                                      options: _vm.revisions,
                                      limit: _vm.limit
                                    },
                                    on: {
                                      "selected-item": _vm.setSelectedRevision
                                    },
                                    model: {
                                      value: _vm.selectedRevision,
                                      callback: function($$v) {
                                        _vm.selectedRevision = $$v
                                      },
                                      expression: "selectedRevision"
                                    }
                                  })
                                : _vm._e(),
                              _c("Loader", {
                                attrs: {
                                  loading: _vm.isRevisionLoader,
                                  size: _vm.size
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.identifierId === 2
                    ? _c(
                        "div",
                        { staticClass: "flex flex-row filters__item" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "label font-bold text-right pr-4 w-1/4"
                            },
                            [_vm._v("Brand:")]
                          ),
                          _c(
                            "div",
                            { staticClass: "select-box pl-2 relative w-3/4" },
                            [
                              _vm.isBrandRefresh
                                ? _c("AutoCompleteDropdown", {
                                    attrs: { options: _vm.brands },
                                    on: {
                                      "selected-item": _vm.setSelectedBrand
                                    },
                                    model: {
                                      value: _vm.selectedBrand,
                                      callback: function($$v) {
                                        _vm.selectedBrand = $$v
                                      },
                                      expression: "selectedBrand"
                                    }
                                  })
                                : _vm._e(),
                              _c("Loader", {
                                attrs: {
                                  loading: _vm.isBrandLoader,
                                  size: _vm.size
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.identifierId === 2
                    ? _c(
                        "div",
                        { staticClass: "flex flex-row filters__item" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "label font-bold text-right pr-4 w-1/4"
                            },
                            [
                              _vm._v("Product:"),
                              _c("span", { staticClass: "text-red-600" }, [
                                _vm._v("*")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "select-box pl-2 relative w-3/4" },
                            [
                              _vm.isProductRefresh
                                ? _c("AutoCompleteDropdown", {
                                    attrs: {
                                      options: _vm.products,
                                      placeholder:
                                        "type minimum 3 letters to search products",
                                      apiPath: _vm.apiProductPath,
                                      isAutoFetch: "",
                                      autoFetchType: "ProductReports"
                                    },
                                    on: {
                                      "selected-item": _vm.setSelectedProduct
                                    },
                                    model: {
                                      value: _vm.selectedProduct,
                                      callback: function($$v) {
                                        _vm.selectedProduct = $$v
                                      },
                                      expression: "selectedProduct"
                                    }
                                  })
                                : _vm._e(),
                              _c("Loader", {
                                attrs: {
                                  loading: _vm.isProductLoader,
                                  size: _vm.size
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.identifierId === 2
                    ? _c(
                        "div",
                        { staticClass: "flex flex-row filters__item" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "label font-bold text-right pr-4 w-1/4"
                            },
                            [
                              _vm._v("Variation:"),
                              _c("span", { staticClass: "text-red-600" }, [
                                _vm._v("*")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "select-box pl-2 relative w-3/4" },
                            [
                              _vm.isVariationRefresh
                                ? _c("AutoCompleteDropdown", {
                                    attrs: {
                                      options: _vm.variations,
                                      placeholder:
                                        "type minimum 3 letters to search variations",
                                      apiPath: _vm.apiVariationPath,
                                      isAutoFetch: "",
                                      autoFetchType: "VariationReports"
                                    },
                                    on: {
                                      "selected-item": _vm.setSelectedVariation
                                    },
                                    model: {
                                      value: _vm.selectedVariation,
                                      callback: function($$v) {
                                        _vm.selectedVariation = $$v
                                      },
                                      expression: "selectedVariation"
                                    }
                                  })
                                : _vm._e(),
                              _c("Loader", {
                                attrs: {
                                  loading: _vm.isVariationLoader,
                                  size: _vm.size
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "container",
                  staticStyle: { "margin-top": "10px", "padding-left": "0" }
                },
                [
                  _vm.isLoader
                    ? _c("Loader", { attrs: { loading: _vm.isLoader } })
                    : _c("div", { staticClass: "view-reports" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm.identifierId === 1
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedCucCode != "" &&
                                        _vm.selectedRevision != ""
                                        ? "Report for Cuc Code " +
                                            _vm.selectedCucCode +
                                            ", Revision " +
                                            _vm.selectedRevision
                                        : ""
                                    ) +
                                    " "
                                )
                              ])
                            : _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedProduct != ""
                                        ? "Report for Product " +
                                            _vm.selectedProduct
                                        : ""
                                    ) +
                                    " "
                                )
                              ])
                        ]),
                        _c("div", { staticClass: "table-wrapper" }, [
                          _c(
                            "table",
                            { staticClass: "pioti-table table-auto" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Country")]),
                                  _c("th", [_vm._v("Language")]),
                                  _c("th", [_vm._v("Brand")]),
                                  _c("th", [_vm._v("CUC Code")]),
                                  _c("th", [_vm._v("Revision")]),
                                  _c("th", [_vm._v("Product")]),
                                  _c("th", [_vm._v("Marketed")]),
                                  _c("th", [_vm._v("Variation")]),
                                  _c("th", [_vm._v("Variation Start Date")]),
                                  _c("th", [_vm._v("Variation End Date")]),
                                  _c("th", [_vm._v("CUC Status")]),
                                  _c("th", [_vm._v("CUC Start Date")]),
                                  _c("th", [_vm._v("CUC End Date")]),
                                  _c("th", [_vm._v("Historic")])
                                ])
                              ]),
                              _vm.reports.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(_vm.reports, function(row, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Country,
                                                subString: _vm.subString,
                                                position: "right"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(_vm._s(row.Language))
                                        ]),
                                        _c("td", [_vm._v(_vm._s(row.Brand))]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.PlmMaterialId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.Revision))
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Product,
                                                subString: _vm.subString,
                                                position: "right"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Checkbox", {
                                              attrs: { checked: row.Marketed }
                                            }),
                                            row.Marketed
                                              ? _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Product will flow to WIOP"
                                                  }
                                                })
                                              : _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Product will not flow to WIOP"
                                                  }
                                                })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.ProductVariationName,
                                                subString: _vm.subString,
                                                position: "right"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.ProductVariationStartDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.ProductVariationEndDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.CucStatusText))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.CucStartDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.CucEndDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Checkbox", {
                                              attrs: { checked: row.Historic }
                                            }),
                                            row.Historic
                                              ? _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Formulation will flow to WIOP"
                                                  }
                                                })
                                              : _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Formulation will not flow to WIOP"
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "14" } },
                                        [_c("NoDetailsBox")],
                                        1
                                      )
                                    ])
                                  ])
                            ]
                          )
                        ])
                      ])
                ],
                1
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }