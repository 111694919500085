var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c("section", { staticClass: "product-report" }, [
              _c("div", { staticClass: "title flex justify-between" }, [
                _c("div", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("ExportExcel", {
                      attrs: {
                        exportData: _vm.reportsData,
                        tableHeaders: _vm.exportDataHeaders,
                        infoTableDataRows: _vm.infoTableData,
                        fileName: _vm.exportFileName
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "product__info" }, [
                _c("div", { staticClass: "filters flex" }, [
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [_vm._v("Country:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isCountryRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: { options: _vm.countries, multiple: "" },
                              on: { "selected-item": _vm.setSelectedCountry },
                              model: {
                                value: _vm.selectedCountry,
                                callback: function($$v) {
                                  _vm.selectedCountry = $$v
                                },
                                expression: "selectedCountry"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: {
                            loading: _vm.isCountryLoader,
                            size: _vm.size
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [_vm._v("Brand:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isBrandRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: { options: _vm.brands, multiple: "" },
                              on: { "selected-item": _vm.setSelectedBrand },
                              model: {
                                value: _vm.selectedBrand,
                                callback: function($$v) {
                                  _vm.selectedBrand = $$v
                                },
                                expression: "selectedBrand"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: { loading: _vm.isBrandLoader, size: _vm.size }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [_vm._v("Product:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isProductRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: {
                                options: _vm.products,
                                placeholder:
                                  "type minimum 3 letters to search products",
                                apiPath: _vm.apiPath,
                                isAutoFetch: "",
                                autoFetchType: "ProductReports"
                              },
                              on: { "selected-item": _vm.setSelectedProduct },
                              model: {
                                value: _vm.selectedProduct,
                                callback: function($$v) {
                                  _vm.selectedProduct = $$v
                                },
                                expression: "selectedProduct"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: {
                            loading: _vm.isProductLoader,
                            size: _vm.size
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex flex-row filters__item" }, [
                    _c(
                      "label",
                      { staticClass: "label font-bold text-right pr-4 w-1/4" },
                      [_vm._v("Variation:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "select-box pl-2 relative w-3/4" },
                      [
                        _vm.isVariationRefresh
                          ? _c("AutoCompleteDropdown", {
                              attrs: {
                                options: _vm.variations,
                                placeholder:
                                  "type minimum 3 letters to search variations",
                                apiPath: _vm.apiVariationPath,
                                isAutoFetch: "",
                                autoFetchType: "VariationReports"
                              },
                              on: { "selected-item": _vm.setSelectedVariation },
                              model: {
                                value: _vm.selectedVariation,
                                callback: function($$v) {
                                  _vm.selectedVariation = $$v
                                },
                                expression: "selectedVariation"
                              }
                            })
                          : _vm._e(),
                        _c("Loader", {
                          attrs: {
                            loading: _vm.isVariationLoader,
                            size: _vm.size
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "filters__item justify-evenly" },
                    [
                      _c(
                        "Button",
                        {
                          staticClass: "ml-8",
                          attrs: { disabled: _vm.isView },
                          on: { click: _vm.fetchReport }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 20 20"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                }
                              })
                            ]
                          ),
                          _vm._v(" View ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "container",
                  staticStyle: { "margin-top": "10px", "padding-left": "0" }
                },
                [
                  _vm.isLoader
                    ? _c("Loader", { attrs: { loading: _vm.isLoader } })
                    : _c("div", { staticClass: "view-reports" }, [
                        _vm.reports.length > 0
                          ? _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedProduct != ""
                                      ? "Report for Product " +
                                          _vm.selectedProduct
                                      : ""
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "table-wrapper" }, [
                          _c(
                            "table",
                            { staticClass: "pioti-table table-auto" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Country")]),
                                  _c("th", [_vm._v("Language")]),
                                  _c("th", [_vm._v("Brand")]),
                                  _c("th", [_vm._v("Product")]),
                                  _c("th", [_vm._v("Product Start Date")]),
                                  _c("th", [_vm._v("Product End Date")]),
                                  _c("th", [_vm._v("Variation")]),
                                  _c("th", [_vm._v("Variation Start Date")]),
                                  _c("th", [_vm._v("Variation End Date")]),
                                  _c("th", [_vm._v("Marketed")])
                                ])
                              ]),
                              _vm.reports.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(_vm.reports, function(row, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Country_Name,
                                                position: "right",
                                                subString: _vm.subString
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Language_Name,
                                                position: "right",
                                                subString: _vm.subString
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Brand_Name,
                                                position: "right",
                                                subString: _vm.subString
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Product_Name,
                                                position: "left",
                                                subString: _vm.subString
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.ProductStartDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.ProductEndDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Ellipsis", {
                                              attrs: {
                                                data: row.Variation_Name,
                                                position: "left",
                                                subString: _vm.subString
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.VariationStartDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.VariationEndDate
                                              )
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tooltip table-tooltip"
                                          },
                                          [
                                            _c("Checkbox", {
                                              attrs: { checked: row.Marketed }
                                            }),
                                            row.Marketed
                                              ? _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Product will flow to WIOP"
                                                  }
                                                })
                                              : _c("Tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    hoverText:
                                                      "Product will not flow to WIOP"
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "14" } },
                                        [_c("NoDetailsBox")],
                                        1
                                      )
                                    ])
                                  ])
                            ]
                          )
                        ])
                      ])
                ],
                1
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }