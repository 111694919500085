<template>
  <div>
    <div v-if="isFiltered">
      <Loader :loading="isLoader" v-if="isLoader" />
      <div class="container" v-else>
        <div class="table-wrapper">
          <table class="pioti-table table-auto products">
            <thead>
              <tr>
                <th>Brand</th>
                <th>Product Name</th>
                <th>Product Start Date</th>
                <th>Product End Date</th>
                <th class="actions"></th>
              </tr>
            </thead>
            <tbody v-if="tableDataRows[0].ProductId != 0">
              <tr v-for="(row, index) in tableDataRows" :key="index">
                <td>{{ row.BrandName }}</td>
                <td class="tooltip table-tooltip">
                  {{ row.ProductName }}
                </td>
                <td>{{ row.ProductStartDate | formatDate }}</td>
                <td>{{ row.ProductEndDate | formatDate }}</td>
                <td class="actions" v-if="role">
                  <IconButton
                    @click="openModalProduct()"
                    :buttonType="buttonType"
                    v-if="role.isUser"
                    >Edit</IconButton
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <NoDetailsBox />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Variations table -->
        <div class="product-variations" v-if="tableDataRows[0].ProductId != 0">
          <div class="title">
            {{
              tableDataRows[0].ProductId == 0
                ? "No Variations found"
                : "Product Variations for " + tableDataRows[0].ProductName
            }}
          </div>
          <table class="pioti-table table-auto">
            <thead>
              <tr>
                <th>Country</th>
                <th>Language</th>
                <th>Marketed</th>
                <th>Variation Name</th>
                <th>Variation Start Date</th>
                <th>Variation End Date</th>
              </tr>
            </thead>
            <tbody v-if="tableDataRows[0].ProductVariations">
              <tr
                v-for="(row, index) in tableDataRows[0].ProductVariations"
                :key="index"
              >
                <td>{{ row.Country_Name }}</td>
                <td>{{ row.Language_Name }}</td>
                <td class="tooltip table-tooltip">
                  <Checkbox :checked="row.Marketed" />
                  <Tooltip
                    v-if="row.Marketed"
                    :position="'left'"
                    :hoverText="'Product will flow to WIOP'"
                  />
                  <Tooltip
                    v-else
                    :position="'left'"
                    :hoverText="'Product will not flow to WIOP'"
                  />
                </td>
                <td class="tooltip table-tooltip">
                  <Ellipsis :data="row.Variation_Name" :subString="subString" />
                </td>
                <td>{{ row.VariationStartDate | formatDate }}</td>
                <td>{{ row.VariationEndDate | formatDate }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">
                  <NoDetailsBox />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <InfoBox
        message="Select Product or Variation to view General Information"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters,  mapMutations } from "vuex";
import { Bus } from "../../eventBus";
import "../../filters";
import loggerService from '../../services/logger';
import productService from "../../services/products";

const Product = () => import(/* webpackPrefetch: true */ "../common/Product");
export default {
  components: {
    InfoBox: () => import(/* webpackPrefetch: true */ "../common/InfoBox"),
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    IconButton: () =>
      import(/* webpackPrefetch: true */ "../common/IconButton"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
  },
  data: () => ({
    isLoader: true,
    buttonType: "edit",
    tableDataRows: null,
    subString: 20,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = true;
      this.getGeneralInfo();
    }, 1000);
  },
  computed: {
    ...mapGetters({
      isFiltered: "GET_IS_FILTERED",
      role: "GET_USER_ROLE",
      brand: "GET_BRAND_IDS",
      countries: "GET_COUNTRIES",
    }),
  },
  methods: {
    ...mapMutations({
      updateIsProductType: "SET_IS_PRODUCT_TYPE",
    }),
    openModalProduct() {
      this.updateIsProductType("edit");
      Bus.$emit("open", {
        component: Product,
        title: "Edit Product",
        size: "xl",
        closeOnClick: false,
      });
    },
    async getGeneralInfo() {
      const countryIds = this.countries.map((country) => country.Id);
      if (this.brand.productId || this.brand.variationId) {
        try {
          const payLoad={
            "cIds":countryIds.join(),
            "productId": this.brand.productId === "" ? null : this.brand.productId,
            "variationId":this.brand.variationId === "" ||
                this.brand.variationId === undefined
                  ? ""
                  : this.brand.variationId
          };
          const { data } = await productService.getProduct(payLoad);

          this.tableDataRows = [data];
          this.isLoader = false;
        } catch (error) {
          loggerService.logError(error);
          this.isLoader = false;
          this.tableDataRows = [];
        }
      }
    },
  },
  watch: {
    brand(newVal) {
      if (newVal.length === 0) {
        let data = {
          ProductId: 0,
        };
        this.tableDataRows = [data];
      } else {
        this.getGeneralInfo();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
  border-radius: 3px;
  table.products {
    border-radius: 3px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    tr {
      th,
      td {
        &:nth-child(2) {
          min-width: 400px;
          max-width: 400px;
        }
      }
    }
  }
}
.product-variations {
  background: #fff;
  margin-top: 20px;
  padding: 0 10px 10px 10px;
  border-radius: 3px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  .title {
    color: #337ab7;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 10px 10px 0;
  }
}
</style>
