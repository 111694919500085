<template>
  <div>
    <div v-if="isFiltered">
      <Loader :loading="isLoader" v-if="isLoader" />
      <div class="container" v-else>
        <div>
          <div class="table-wrapper">
            <table class="pioti-table table-auto">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Language</th>
                  <th>Brand</th>
                  <th>Product</th>
                  <th>Variation</th>
                  <th>CUC Code</th>
                  <th>Revision</th>
                  <th>Description</th>
                  <th>CUC Start Date</th>
                  <th>CUC End Date</th>
                  <th>Status</th>
                  <th class="actions">Ingredients</th>
                </tr>
              </thead>
              <tbody v-if="tableDataRows.length > 0">
                <tr v-for="(row, index) in tableDataRows" :key="index">
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Country" :subString="subString" />
                  </td>
                  <td>{{ row.Language }}</td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Brand" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Product" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Variation" />
                  </td>
                  <td>{{ row.CucCode }}</td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Revision" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Description" />
                  </td>
                  <td>{{ row.StartDate | formatDate }}</td>
                  <td>{{ row.EndDate | formatDate }}</td>
                  <td>{{ row.Status }}</td>
                  <td class="actions">
                    <IconButton
                      @click="showIngredients(row)"
                      :buttonType="buttonType"
                      >View</IconButton
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12"><NoDetailsBox /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--Ingredients-->
          <div
            class="ingredients"
            v-if="isIngredients && tableDataRows.length > 0"
          >
            <div class="title">
              Ingredients for CucCode: {{ cucCode }}, Revision: {{ revision }}
            </div>
            <Ingredients :tableDataRows="ingredients" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <InfoBox
        message="Select Product or Variation to see the Assigned Formulation/CUC details"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loggerService from "../../services/logger";
import "../../filters";
import productService from "../../services/products";

export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    InfoBox: () => import(/* webpackPrefetch: true */ "../common/InfoBox"),
    IconButton: () =>
      import(/* webpackPrefetch: true */ "../common/IconButton"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ingredients: () =>
      import(/* webpackPrefetch: true */ "./ViewIngredientsPopup"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
  },
  data: () => ({
    isLoader: true,
    buttonType: "view",
    tableDataRows: [],
    ingredients: null,
    cucCode: null,
    revision: null,
    isIngredients: false,
    subString: 8,
  }),
  created() {
    this.getAssignedFormulation();
  },
  computed: {
    ...mapGetters({
      isFiltered: "GET_IS_FILTERED",
      brands: "GET_BRAND_IDS",
    }),
  },
  methods: {
    showIngredients(data) {
      this.ingredients = data.Ingredients;
      this.cucCode = data.CucCode;
      this.revision = data.Revision;
      this.isIngredients = true;
    },
    async getAssignedFormulation() {
      this.isLoader = true;
      let payLoad = {
        brandId:
          this.brands.brandnames === "" || this.brands.brandnames === undefined
            ? "Any"
            : this.brands.brandnames.join(),
        productId: this.brands.productId === "" ? null : this.brands.productId,
        countryId: this.brands.countryId === "" ? null : this.brands.countryId,
        variationId:
          this.brands.variationId === "" ? null : this.brands.variationId,
      };
      if (this.brands.productId || this.brands.variationId) {
        try {
          const { data } = await productService.getAssignedFormulation(payLoad);

          this.tableDataRows = data;
          this.isLoader = false;
          this.isIngredients = false;
        } catch (error) {
          loggerService.logError(error);
          this.isLoader = false;
          this.tableDataRows = [];
          this.isIngredients = false;
        }
      }
    },   
  },
  watch: {
    brands(newVal) {
      if (newVal.length === 0) {
        this.tableDataRows = [];
      } else {
        this.getAssignedFormulation();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
  border-radius: 3px;
  table {
    border-radius: 3px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    tr {
      th,
      td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          min-width: 70px;
          max-width: 70px;
        }
        &:nth-child(7),
        &:nth-child(11) {
          min-width: 70px;
          max-width: 70px;
        }
      }
      &:nth-child(9),
      &:nth-child(10) {
        min-width: 110px;
        max-width: 110px;
      }
    }
  }
}
.ingredients {
  background: #fff;
  margin-top: 20px;
  padding: 0 10px 10px 10px;
  border-radius: 3px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  .title {
    color: #337ab7;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px;
  }
}
</style>
