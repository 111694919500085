var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isFiltered
      ? _c(
          "div",
          [
            _vm.isLoader
              ? _c("Loader", { attrs: { loading: _vm.isLoader } })
              : _c("div", { staticClass: "container" }, [
                  _c("div", [
                    _c("div", { staticClass: "table-wrapper" }, [
                      _c("table", { staticClass: "pioti-table table-auto" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Country")]),
                            _c("th", [_vm._v("Language")]),
                            _c("th", [_vm._v("Brand")]),
                            _c("th", [_vm._v("Product")]),
                            _c("th", [_vm._v("Variation")]),
                            _c("th", [_vm._v("CUC Code")]),
                            _c("th", [_vm._v("Revision")]),
                            _c("th", [_vm._v("Description")]),
                            _c("th", [_vm._v("CUC Start Date")]),
                            _c("th", [_vm._v("CUC End Date")]),
                            _c("th", [_vm._v("Status")]),
                            _c("th", { staticClass: "actions" }, [
                              _vm._v("Ingredients")
                            ])
                          ])
                        ]),
                        _vm.tableDataRows.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.tableDataRows, function(row, index) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Country,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [_vm._v(_vm._s(row.Language))]),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Brand,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Product,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: { data: row.Variation }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [_vm._v(_vm._s(row.CucCode))]),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Revision,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: { data: row.Description }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(row.StartDate)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm._f("formatDate")(row.EndDate))
                                    )
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.Status))]),
                                  _c(
                                    "td",
                                    { staticClass: "actions" },
                                    [
                                      _c(
                                        "IconButton",
                                        {
                                          attrs: { buttonType: _vm.buttonType },
                                          on: {
                                            click: function($event) {
                                              return _vm.showIngredients(row)
                                            }
                                          }
                                        },
                                        [_vm._v("View")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "12" } },
                                  [_c("NoDetailsBox")],
                                  1
                                )
                              ])
                            ])
                      ])
                    ]),
                    _vm.isIngredients && _vm.tableDataRows.length > 0
                      ? _c(
                          "div",
                          { staticClass: "ingredients" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " Ingredients for CucCode: " +
                                  _vm._s(_vm.cucCode) +
                                  ", Revision: " +
                                  _vm._s(_vm.revision) +
                                  " "
                              )
                            ]),
                            _c("Ingredients", {
                              attrs: { tableDataRows: _vm.ingredients }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c("InfoBox", {
              attrs: {
                message:
                  "Select Product or Variation to see the Assigned Formulation/CUC details"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }