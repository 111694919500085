<template>
  <div class="page-container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else class="container">
      <section class="product-report">
        <div class="title flex justify-between">
          <div>{{ title }}</div>
          <div class="flex">
            <ExportExcel
              :exportData="reportsData"
              :tableHeaders="exportDataHeaders"
              :infoTableDataRows="infoTableData"
              :fileName="exportFileName"
            />
            <!--  <Button class="ml-8" :disabled="isView" @click="fetchReport">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                />
              </svg>
              View Report
            </Button> -->
          </div>
        </div>
        <div class="product__info">
          <div class="filters flex">
            <!--Country-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Country:</label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="countries"
                  v-model="selectedCountry"
                  @selected-item="setSelectedCountry"
                  multiple
                  v-if="isCountryRefresh"
                />
                <Loader :loading="isCountryLoader" :size="size" />
              </div>
            </div>
            <!--Brand-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Brand:</label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="brands"
                  v-model="selectedBrand"
                  @selected-item="setSelectedBrand"
                  multiple
                  v-if="isBrandRefresh"
                />
                <Loader :loading="isBrandLoader" :size="size" />
              </div>
            </div>
            <!--Product-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Product:</label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="products"
                  v-model="selectedProduct"
                  @selected-item="setSelectedProduct"
                  v-if="isProductRefresh"
                  placeholder="type minimum 3 letters to search products"
                  :apiPath="apiPath"
                  isAutoFetch
                  autoFetchType="ProductReports"
                /><Loader :loading="isProductLoader" :size="size" />
              </div>
            </div>
            <!--Variation-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >Variation:</label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="variations"
                  v-model="selectedVariation"
                  @selected-item="setSelectedVariation"
                  v-if="isVariationRefresh"
                  placeholder="type minimum 3 letters to search variations"
                  :apiPath="apiVariationPath"
                  isAutoFetch
                  autoFetchType="VariationReports"
                />
                <Loader :loading="isVariationLoader" :size="size" />
              </div>
            </div>
            <div class="filters__item justify-evenly">
              <Button class="ml-8" :disabled="isView" @click="fetchReport">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                  />
                </svg>
                View
              </Button>
            </div>
          </div>
        </div>
        <!-- View reports for selected cuc code -->
        <div class="container" style="margin-top: 10px; padding-left: 0">
          <Loader :loading="isLoader" v-if="isLoader" />
          <div class="view-reports" v-else>
            <div class="title" v-if="reports.length > 0">
              {{
                selectedProduct != ""
                  ? "Report for Product " + selectedProduct
                  : ""
              }}
            </div>
            <div class="table-wrapper">
              <table class="pioti-table table-auto">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Language</th>
                    <th>Brand</th>
                    <th>Product</th>
                    <th>Product Start Date</th>
                    <th>Product End Date</th>
                    <th>Variation</th>
                    <th>Variation Start Date</th>
                    <th>Variation End Date</th>
                    <th>Marketed</th>
                  </tr>
                </thead>
                <tbody v-if="reports.length > 0">
                  <tr v-for="(row, index) in reports" :key="index">
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Country_Name"
                        position="right"
                        :subString="subString"
                      />
                    </td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Language_Name"
                        position="right"
                        :subString="subString"
                      />
                    </td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Brand_Name"
                        position="right"
                        :subString="subString"
                      />
                    </td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Product_Name"
                        position="left"
                        :subString="subString"
                      />
                    </td>
                    <td>{{ row.ProductStartDate | formatDate }}</td>
                    <td>{{ row.ProductEndDate | formatDate }}</td>
                    <td class="tooltip table-tooltip">
                      <Ellipsis
                        :data="row.Variation_Name"
                        position="left"
                        :subString="subString"
                      />
                    </td>
                    <td>{{ row.VariationStartDate | formatDate }}</td>
                    <td>{{ row.VariationEndDate | formatDate }}</td>
                    <td class="tooltip table-tooltip">
                      <Checkbox :checked="row.Marketed" />
                      <Tooltip
                        v-if="row.Marketed"
                        :position="'left'"
                        :hoverText="'Product will flow to WIOP'"
                      />
                      <Tooltip
                        v-else
                        :position="'left'"
                        :hoverText="'Product will not flow to WIOP'"
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="14">
                      <NoDetailsBox />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import { mapMutations } from "vuex";
import "../../filters";
import reportService from "../../services/reports";
import loggerService from "../../services/logger";

export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "../common/AutoCompleteDropdown"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    ExportExcel: () =>
      import(/* webpackPrefetch: true */ "../common/ExportExcel"),
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
  },
  data: () => ({
    subString: 15,
    limit: 1,
    title: "Brand Product Mapping Report",
    isLoader: true,
    size: "5px",
    reports: [],
    reportsData: [],
    exportDataHeaders: [
      {
        Country: "Country",
        Language: "Language",
        Product: "Product",
        ProductStartDate: "Product Start Date",
        ProductEndDate: "Product End Date",
        Variation: "Variation",
        VariationStartDate: "Variation Start Date",
        VariationEndDate: "Variation End Date",
        Marketed: "Marketed",
      },
    ],
    infoTableData: [],
    exportFileName: "BrandProductMappingReport",
    isDownloading: false,
    brands: [],
    products: [],
    countries: [],
    variations: [],
    selectedBrand: "",
    brandId: "",
    brandIds: "",
    selectedBrands: "",
    selectedProduct: "",
    productId: "",
    selectedCountry: "",
    countryId: "",
    countryIds: "",
    selectedCountries: "",
    selectedVariation: "",
    variationId: "",
    isBrandRefresh: true,
    isProductRefresh: true,
    isCountryRefresh: true,
    isVariationRefresh: true,
    isBrandLoader: false,
    isProductLoader: false,
    isCountryLoader: false,
    isVariationLoader: false,
    header: null,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.getBrands();
      this.getCountries();
      // this.getVariations();
    }, 500);
  },
  computed: {
    apiPath() {
      return "FormulationMapping/GetProductListByContriesandBrands";
    },
    apiVariationPath() {
      return "FormulationMapping/GetProductVariationNamesForDD";
    },
    isView() {
      return this.selectedCountries != "" ||
        this.selectedBrands != "" ||
        this.selectedProduct != "" ||
        this.selectedVariation != ""
        ? false
        : true;
    },
  },
  methods: {
    // ...mapActions({
    //   updateBrand: "fetchBrandInReports",
    //   updateCountries: "fetchCountriesInReports",
    // }),
    ...mapMutations({
      updateCountries: "SET_COUNTRIES_IN_REPORTS",
      updateBrand: "SET_BRAND_IN_REPORTS",
    }),
    setSelectedCountry(country) {
      if (country === true) {
        this.selectedCountry = "";
        this.countryId = "";
        this.selectedCountries = [];
        this.countryIds = [];
        this.selectedVariation = "";
        this.variationId = "";
        this.selectedBrand = "";
        this.brandId = "";
        this.selectedBrands = [];
        this.brandIds = [];
        this.selectedProduct = "";
        this.productId = "";
        this.reports = [];
        this.reportsData = [];
        this.isBrandsRefresh();
        this.isProductsRefresh();
        this.isVariationsRefresh();
        return;
      }

      this.selectedCountry = country.Value;
      this.countryId = country.Id;
      this.countryIds = country.map((item) => {
        return item.Id;
      });
      this.selectedCountries = country.map((item) => {
        return item.Value;
      });

      if (
        this.selectedCountries === "" ||
        this.selectedCountries.length === 0
      ) {
        this.reports = [];
        this.reportsData = [];
      }
      this.updateCountries(this.countryIds.join());

      this.isBrandLoader = true;
      setTimeout(() => {
        this.isBrandLoader = false;
      }, 500);
      this.getBrands();
      this.isBrandsRefresh();
      this.isProductsRefresh();
      this.isVariationsRefresh();
    },
    setSelectedBrand(brand) {
      if (brand === true) {
        this.selectedBrand = "";
        this.selectedProduct = "";
        this.productId = "";
        this.variationId = "";
        this.reports = [];
        this.brandIds = [];
        this.selectedBrands = [];
        this.isVariationsRefresh();
        this.isProductsRefresh();
        return;
      }
      this.selectedBrand = brand.Value;
      this.brandId = brand.Id;
      this.brandIds = brand.map((item) => {
        return item.Id;
      });
      this.selectedBrands = brand.map((item) => {
        return item.Value;
      });
      if (this.selectedBrands === "" || this.selectedBrands.length === 0) {
        this.reports = [];
        this.reportsData = [];
      }
      this.updateBrand(this.selectedBrands.join());
      this.isProductsRefresh();
    },
    setSelectedProduct(product) {
      if (product === true) {
        this.selectedProduct = "";
        this.productId = "";
        this.selectedVariation = "";
        this.variationId = "";
        this.reports = [];
        this.reportsData = [];
        this.isVariationsRefresh();
        return;
      }
      this.selectedProduct = product.Value;
      this.productId = product.Id;
      this.isVariationsRefresh();
    },

    setSelectedVariation(variation) {
      if (variation === true) {
        this.selectedVariation = "";
        this.variationId = "";
        this.reports = [];
        this.reportsData = [];
        return;
      }
      this.selectedProduct = variation.Value;
      this.variationId = variation.Id;
    },
    async getCountries() {
      const { data } = await reportService.getAllCountries();
      this.countries = data;
      this.isCountryLoader = false;
      this.isCountriesRefresh();
    },
    async getBrands() {
      const payLoad = {
        cIds:
          this.countryIds === "" || this.countryIds.length === 0
            ? ""
            : this.countryIds.join(),
      };
      const { data } = await reportService.getBrands(payLoad);

      this.brands = data;
      this.isBrandLoader = false;
      this.isBrandsRefresh();
    },
    async fetchReport() {
      let payLoad = {
        countryId:
          this.countryIds === "" || this.countryIds.length === 0
            ? null
            : this.countryIds.join(),
        brandName:
          this.selectedBrands === "" || this.selectedBrands.length === 0
            ? null
            : this.selectedBrands.join(),
        productId: this.productId === "" ? null : this.productId,
        variationId: this.variationId === "" ? null : this.variationId,
      };
      try {
        const { data } = await reportService.getProductVariationReport(payLoad);

        if (data.length === 0 || data === null || data === undefined) {
         
          loggerService.logError("No data to view/download");

          this.reports = [];
          this.reportsData = [];
          this.isDownloading = false;
        } else {
          this.reports = data;
          this.reportsData = data;
          delete this.reportsData[0]["Product_Id"];
          delete this.reportsData[0]["Brand_ID"];
          delete this.reportsData[0]["Product_ID"];
          delete this.reportsData[0]["Variation_Id"];
          delete this.reportsData[0]["Country_Id"];
          delete this.reportsData[0]["Language_Id"];
          delete this.reportsData[0]["CreatedBy"];
          delete this.reportsData[0]["CreatedDate"];
          delete this.reportsData[0]["UpdatedBy"];
          delete this.reportsData[0]["UpdatedDate"];
          delete this.reportsData[0]["VariationCreatedBy"];
          delete this.reportsData[0]["VariationCreatedDate"];
          delete this.reportsData[0]["VariationUpdatedBy"];
          delete this.reportsData[0]["VariationUpdatedDate"];
          this.infoTableData[0] = {
            Country:
              this.selectedCountries === "" ||
              this.selectedCountries.length === 0
                ? null
                : this.selectedCountries.join(),
            Brand:
              this.selectedBrands === "" || this.selectedBrands.length === 0
                ? null
                : this.selectedBrands.join(),
            Product: this.selectedProduct,
            Variation: this.selectedVariation,
          };
          this.header = [
            "Country:" + this.selectedCountries === "" ||
            this.selectedCountries.length === 0
              ? null
              : this.selectedCountries.join(),
            "Brand: " + this.selectedBrands === "" ||
            this.selectedBrands.length === 0
              ? null
              : this.selectedBrands.join(),
            "Product: " + this.selectedProduct,
            "Variation:" + this.selectedVariation,
            "",
            "",
            "",
          ];
          return data;
        }
      } catch (error) {
        loggerService.logError(error);

        this.reports = [];
        this.isDownloading = false;
      }
    },
    isBrandsRefresh() {
      this.isBrandRefresh = false;
      this.$nextTick(() => {
        this.isBrandRefresh = true;
      });
    },
    isCountriesRefresh() {
      this.isCountryRefresh = false;
      this.$nextTick(() => {
        this.isCountryRefresh = true;
      });
    },
    isProductsRefresh() {
      this.isProductRefresh = false;
      this.$nextTick(() => {
        this.isProductRefresh = true;
      });
    },
    isVariationsRefresh() {
      this.isVariationRefresh = false;
      this.$nextTick(() => {
        this.isVariationRefresh = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  max-height: 170px;
  overflow-y: auto;
}
</style>