import axios from "../http-common";
import loggerService from "./logger";
import authentication from "../authentication";

export default {
    getBrands(payLoad) {
        return axios.get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetAllBrands/${
                    payLoad.cIds
            }`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getAllCountries() {
        return axios.get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetAllCountries`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getNominalCompositionReport(cucCode) {
        return axios.get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetNominalCompositionReportData/${cucCode}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getProductVariationReport(payLoad) {
        return axios.post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetProductVariationList`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getCucCodeReport(payLoad) {
        return axios.get(
                `${
              process.env.VUE_APP_API_URL
            }/FormulationMapping/GetFormulationMappingListForReport/${authentication.getUserEmail()}/${
                payLoad.cucCode
            }/${payLoad.revision}`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getProductReport(payLoad) {
        return axios.post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetFormulationMappingListByProductorVariationId`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    }


}